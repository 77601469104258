
  import { Component, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'

  import AccountDetails from './components/AccountDetails.vue'
  import ChangePassword from '@/components/change-password/ChangePassword.vue'

  @Component({
    components: {
      AccountDetails,
      ChangePassword
    }
  })
  export default class Account extends Vue {

    /** PRIVATE PROPERTIES */

    private state = new AuthState(this.$store)


    /** COMPUTED PROPERTIES */

    protected get canSeeDetails() {
      return this.user.hasPermission(Claims.SETTINGS_DETAILS, Crud.READ)
    }

    protected get canChangePassword() {
      return this.user.hasPermission(Claims.SETTINGS_PASSWORD, Crud.UPDATE)
    }

    protected get user() {
      return this.state.user
    }
  }


