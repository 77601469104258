
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'


  @Component
  export default class MyDetails extends Vue {


    /** PROPERTIES */

    @Prop()
    public state!: AuthState


  /** COMPUTED PROPERTIES */

    protected get groups() {
      return this.user.roles.map(r => r.name).join(', ')
    }

    protected get user() {
      return this.state.user
    }
  }

