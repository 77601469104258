
  import { Component, Vue } from 'vue-property-decorator'

  import SettingsNavigation from './components/SettingsNavigation.vue'


  @Component({
    components: {
      SettingsNavigation
    }
  })
  export default class Settings extends Vue { }

